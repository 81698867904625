import React from "react"

import Icon from '../Icon/icon'
import facebookIcon from '../../images/facebook-icon.svg';
import instagramIcon from '../../images/instagram-icon.svg';
import linkedinIcon from '../../images/linkedin-icon.svg';
import logoIcon from '../../images/logo.svg';
import homeIcon from '../../images/home-icon.svg';
import logoFooterIcon from '../../images/logo-footer-icon.svg';
import solutionsIcon from '../../images/solutions-icon.svg';
import jobsIcon from '../../images/vagas.png';

import resultsIcon from '../../images/results-icon.svg';
import exploreIcon from '../../images/explore-icon.svg';
import contactIcon from '../../images/contact-icon.svg';
import searchIcon from '../../images/search-icon.svg';
import { Link } from "gatsby"

import styles from '../../styles/footer.module.scss'

const Footer = () => (
  <footer>
    <section className={styles.footerContainer}>
      <div className={styles.footerLogo}>
        <Icon style={styles.logoIcon} imageSrc={logoIcon} />
      </div>
      <div className={styles.footerMenuContainer}>
        <ul style={{justifyContent:"space-evenly"}} className={styles.footerMenu}>

         
          <li>
            <Icon href="/" style={styles.homeIcon} imageSrc={homeIcon} />
            <span>Home</span>
          </li>
         {/*
          <li>
            <Icon href="/" style={styles.logoFooterIcon} imageSrc={logoFooterIcon} />
            <span>Pessoalize</span>
          </li>
          */}
          <li>
            <Icon href="/solution" style={styles.solutionsIcon} imageSrc={solutionsIcon} />
            <span>Soluções</span>
          </li>
          <li>
            <Icon href="https://pessoalize.enlizt.me" style={styles.solutionsIcon} imageSrc={jobsIcon} />
            <span>Vagas</span>
          </li>
          <li>
            <Icon 
              href="https://pessoalize-privacy-policy.s3.us-east-1.amazonaws.com/Política%20de%20Privacidade%20Pessoalize.pdf" 
              style={styles.solutionsIcon} 
              imageSrc={searchIcon} 
            />
            <span>Política de Privacidade</span>
          </li>
          {/*
          <li>
            <Icon style={styles.resultsIcon} imageSrc={resultsIcon} />
            <span>Resultados</span>
          </li>
          <li>
            <Icon style={styles.exploreIcon} imageSrc={exploreIcon} />
            <span>Explorar</span>
          </li> 
          <li>
            <Icon style={styles.contactIcon} imageSrc={contactIcon} />
            <span>Contato</span>
          </li> 
          */}
        </ul>
      </div>
      <div className={styles.socialFooterContainer}>
        <div className={styles.socialFooterWrapper}>
          <Icon style={styles.socialIcon} imageSrc={facebookIcon} href={'https://www.facebook.com/pessoalize'} />
          <Icon style={styles.socialIcon} imageSrc={instagramIcon} href={'https://www.instagram.com/pessoalize/'} />
          <Icon style={styles.socialIcon} imageSrc={linkedinIcon} href={'https://www.linkedin.com/company/pessoalize'} />
        </div>
      </div>
      <div className={styles.footerAdress}>
        Rua Professora Antonieta de Barros, 124 <span>-</span> Florianópolis, SC
      </div>
    </section>
  </footer>
)

export default Footer
