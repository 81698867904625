import { useState, useEffect } from 'react'
import useScript from './useScript'

export default function useHandTalk (isActive) {
    const [htActive, setHtActive] = useState(isActive)
    const [htSrc, setHtSrc] = useState(null)
    const status = useScript(htSrc)

    useEffect(() => {
        if (htActive) {
            setHtSrc('https://plugin.handtalk.me/web/latest/handtalk.min.js')
        }
    }, [htActive, htSrc])

    if (status === 'ready' && window && !window.ht) {
        window.ht = new window.HT({
        token: "123",
        side: process.env.HAND_TALK_TOKEN,
        mobileConfig: {
        side: "left"
        }})
    }

    return [status, setHtActive]
}