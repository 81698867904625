import React from 'react'
import { Link } from "gatsby"

import styles from '../../styles/menu.module.scss'

function Menu() {
    return (
        <div className={styles.menuOptions}>
            <ul>
                <li>
                    <Link to="/" target="_blank" className={`${styles.menuList} ${styles.selected}`}>
                        Home
                    </Link>
                </li>
                  {/* 
                <li>
                    <Link to="/" target="_blank" className={styles.menuList}>
                        Pessoalize
                    </Link>
                </li>
                */}
                <li>
                    <Link to="/solution" target="_blank" className={styles.menuList}>
                        Soluções
                    </Link>
                </li>
                {/* 
                <li>
                    <Link to="/" target="_blank" className={styles.menuList}>
                        Resultados
                    </Link>
                </li>
                <li>
                    <Link to="/" target="_blank" className={styles.menuList}>
                        Explorar
                    </Link>
                </li>
                <li>
                    <Link to="/" target="_blank" className={styles.menuList}>
                        Contato
                    </Link>
                </li>*/}
            </ul>
        </div>
    )
}

export default Menu
