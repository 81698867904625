import React from 'react'
import { Link } from 'gatsby';

import useScrollEffect from '../../hooks/useScrollEffect'
import useHandTalk from '../../hooks/useHandTalk'

import styles from '../../styles/header.module.scss'

import Icon from '../Icon/icon'
import menuHandIcon from '../../images/hand-icon.svg';
import menuVisualIcon from '../../images/visual-icon.svg';
import menuIcon from '../../images/menu-icon.svg';

function HeaderDesktop({ displayMenuHandler }) {
    const defaultClass = `${styles.headerContainer} ${styles.fixed}`
    const scrolledClass = `${styles.scrolled}`
    const headerClass = useScrollEffect(defaultClass, scrolledClass)
    const [status, setHtActive] = useHandTalk()

    function acessibilityClickHandler() {
        return displayMenuHandler(true, false, true)
    }

    function menuClickHandler() {
        return displayMenuHandler(true, true, false)
    }

    async function loadHandTalk() {
        return window.open("https://pslz.in/pessoalize")
    }

    return (
        <div className={headerClass} role="menubar">
            <nav className={styles.navBar} role="navigation">
                <Link className={styles.logoIcon} to='/' tabIndex='0'></Link>
                <div className={styles.menuIconsContainer}>
                    <Icon clickHandler={loadHandTalk} style={styles.menuHand} imageSrc={menuHandIcon} role={'menuitem'} />
                    <Icon clickHandler={acessibilityClickHandler} style={styles.menuVisual} imageSrc={menuVisualIcon} role={'menuitem'} />
                    <Icon clickHandler={menuClickHandler} style={styles.menuIcon} imageSrc={menuIcon} role={'menuitem'} /> 
                </div>
            </nav>
        </div>
    )
}

export default HeaderDesktop
