import React from 'react'

function BackDrop({show}) {
    return (
        <>
            {show &&  <div className="backDrop"/>}
        </>
    )
}

export default BackDrop
