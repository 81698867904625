import React, { useEffect, useRef } from "react"

import useScrollEffect from '../../hooks/useScrollEffect'

import styles from '../../styles/menu.module.scss'

import facebookIcon from '../../images/facebook-icon.svg';
import instagramIcon from '../../images/instagram-icon.svg';
import linkedinIcon from '../../images/linkedin-icon.svg';
import menuHandIcon from '../../images/hand-icon.svg';
import menuVisualIcon from '../../images/visual-icon.svg';
import menuIcon from '../../images/menu-icon.svg';
import menuIconClose from '../../images/menu-icon-close.svg';
import Icon from '../Icon/icon'

function MenuContainer({children, showMenu, displayMenu, displayMenuHandler}) {
    const menuClass = useScrollEffect(`${styles.menuIconsContainer}`, `${styles.menuScrolled}`)
    const ref = useRef(null);
    let containerClass = styles.menuContainer

    if (showMenu) {
        containerClass = `${styles.menuContainer} ${styles.open}`
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)

        return () =>{
            document.removeEventListener('click', handleClickOutside)
        }
    })

    function handleClickOutside(e) {
        if (ref.current && !ref.current.contains(e.target) && showMenu && e.target.alt !== 'icon-menu') {
            close()
        }
    }
    
    function acessibilityClickHandler () {
        return displayMenuHandler(true, false, true)
    }

    function menuClickHandler () {
        return displayMenuHandler(true, true, false)
    }

    function close () {
        return displayMenuHandler(false, false, false)
    }

    function getAcessibilityIcon () {
        return displayMenu.acessibilityOpen && displayMenu.isOpen ? 
            <Icon style={styles.menuIconClose} imageSrc={menuIconClose} clickHandler={close}/> :
            <Icon style={styles.menuVisual} imageSrc={menuVisualIcon} clickHandler={acessibilityClickHandler} type="icon-menu"/> 
    }

    function getMenuIcon () {
        return displayMenu.menuOpen && displayMenu.isOpen ?
            <Icon style={styles.menuIconClose} imageSrc={menuIconClose} clickHandler={close}/> :
            <Icon style={styles.menuIcon} imageSrc={menuIcon} clickHandler={menuClickHandler} type="icon-menu"/>
    }

    return (
        <>
            <section ref={ref} className={containerClass}>
                <div className={menuClass}>
                    <div className={styles.menuIconsWrapper}>
                        <Icon style={styles.menuHand} imageSrc={menuHandIcon} />                    
                        {getAcessibilityIcon()}
                        {getMenuIcon()}
                    </div>
                </div>
                {children}
                <div className={styles.socialMenuContainer}>
                    <div className={styles.socialMenuWrapper}>
                        <Icon style={styles.socialIcon} imageSrc={facebookIcon} href={'https://www.facebook.com/pessoalize'}/>
                        <Icon style={styles.socialIcon} imageSrc={instagramIcon} href={'https://www.instagram.com/pessoalize/'}/>
                        <Icon style={styles.socialIcon} imageSrc={linkedinIcon} href={'https://www.linkedin.com/company/pessoalize'}/>
                    </div>
                </div>
            </section> 
        </>
    )
}

export default MenuContainer
