import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll';

import useScrollEffect from '../../hooks/useScrollEffect'

import styles from '../../styles/header.module.scss'

import Icon from '../Icon/icon'
import logoHeader from '../../images/iso-header-mobile.svg';



function HeaderMobile () {
    const logoMenu = useScrollEffect(`${styles.headerMobile}`, `${styles.show}`, 650, true)
    
    function scrollToTop () {
        scrollTo('header')
    }
    return (
        <div className={logoMenu} onClick={() => scrollToTop()} role='button' tabIndex='0'>
            <Icon style={styles.logoHeader} imageSrc={logoHeader} /> 
        </div>
    )
}

export default HeaderMobile
