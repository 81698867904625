import React, {useEffect, useState} from 'react'

import FontInput from '../FontInput/fontInput'
import { setLocalStorage, getLocalStorage} from '../../utils/utils'

import styles from '../../styles/menu.module.scss'

function Acessibility () {
    const [isBlack, setIsBlack] = useState(false);

    useEffect(() => {
        const { blackTheme } = getLocalStorage('acessibility')
        setIsBlack(blackTheme)
    }, [])

    function setTheme (state) {
        const filter = state ? 'grayscale(100%)' : ''
        setLocalStorage('acessibility', {blackTheme: state})

        return document.documentElement.style.filter = filter
    }

    function toggleTheme (e, state) {
        setIsBlack(state)
        const checkbox = e.target?.id
        const passiveToBlack =  checkbox === 'blackTheme' && !isBlack

        if ((checkbox === 'colorTheme' && !isBlack) || (checkbox === 'blackTheme' && isBlack)) {
            return
        } 
        
        return setTheme(passiveToBlack);
    }
    
    return (
        <div className={styles.acessibilityContainer}>
            <div className={styles.acessibilityContrast}>
                <h4>Contraste</h4>
                <div title="Colorido" className={styles.bgCheckbox}>
                    <label htmlFor={"colorTheme"}/>
                    <input 
                        onClick={(e) => toggleTheme(e, false)} 
                        id={"colorTheme"} 
                        type="checkbox" 
                        className={!isBlack ? styles.selected : ''} 
                    />
                </div>
                <div title="Preto e Branco" className={styles.bgCheckbox}>
                    <label htmlFor="blackTheme"></label>
                    <input 
                        onClick={(e) => toggleTheme(e, true)} 
                        id="blackTheme" 
                        type="checkbox" 
                        className={isBlack ? styles.selected : ''}
                    />
                </div>
            </div>
            <FontInput/>
        </div>
    )
}

export default Acessibility
