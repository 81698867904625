import React, { useContext } from "react"

import HeaderDesktop from './headerDesktop'
import HeaderMobile from './headerMobile'
import { AppContext } from '../../../provider'

function Header ({ displayMenuHandler }) {
  const ctx = useContext(AppContext)
  
  return ctx ? (
  <header>
    {ctx.isMobileDevice ? <HeaderMobile /> : <HeaderDesktop displayMenuHandler={displayMenuHandler} />}
  </header>
  ) : null
} 

export default Header
