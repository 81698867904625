import React, { useEffect, useRef } from 'react'

import { setLocalStorage, getLocalStorage } from '../../utils/utils'

import styles from '../../styles/menu.module.scss'

function FontInput () {
    const zoomInput = useRef(null)
    useEffect(() => {
        const { zoom = 1 } = getLocalStorage('acessibility')
        if (zoomInput.current) {
            zoomInput.current.value = zoom
        }
    }, [])
    
    function changeSize (e) {
        const zoom = e.target?.value
        setLocalStorage('acessibility', {zoom})
        document.documentElement.style.zoom = zoom
    }
    
    return (
        <div className={styles.acessibilityFont}>
            <h4>Tamanho da fonte</h4>
            <div className={styles.rangeWrapper}>
                <span className={styles.acessibilityFontSmaller}>A</span>
                <input ref={zoomInput} onChange={(e) => changeSize(e)}type="range" min='1' max='1.3' step='0.1'/>
                <span className={styles.acessibilityFontBigger}>A</span>
            </div>
        </div>
    )
}

export default FontInput;
