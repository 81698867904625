import React, { useState, useEffect } from 'react'

import { setLocalStorage } from '../../utils/utils'
import useHandTalk from '../../hooks/useHandTalk'

import styles from '../../styles/menu.module.scss'

import Icon from '../Icon/icon'

import homeIcon from '../../images/home-icon.svg';
import logoFooterIcon from '../../images/logo-footer-icon.svg';
import solutionsIcon from '../../images/solutions-icon.svg';
import resultsIcon from '../../images/results-icon.svg';
import exploreIcon from '../../images/explore-icon.svg';
import contactIcon from '../../images/contact-icon.svg';
import acessibilityIcon from '../../images/acessibility-icon.svg';
import menuHandIcon from '../../images/hand-icon-purple.svg';
import menuVisualIcon from '../../images/visual-icon-purple.svg';
import audioIcon from '../../images/audioIcon.svg';
import whatsappIcon from '../../images/whatsappIcon.svg';
import mailIcon from '../../images/mailIcon.svg';
import phoneIcon from '../../images/phoneIcon.svg'

function MenuMobile() {
    const [contactActive, setContactActive] = useState(false)
    const [acessibilityActive, setAcessibilityActive] = useState(false)
    const [isBlack, setIsBlack] = useState(null)
    const [status, setHtActive] = useHandTalk()

    useEffect(() => {
        if (isBlack !== null) {
            setTheme()
        }
    })

    function toggleContact() {
        setContactActive(!contactActive)
    }

    function toogleAcessibility() {
        setAcessibilityActive(!acessibilityActive)
    }

    function toogleTheme() {
        setIsBlack(!isBlack)
    }

    function setTheme() {
        setLocalStorage('acessibility', { blackTheme: isBlack })
        const filter = isBlack ? 'grayscale(100%)' : ''

        return document.documentElement.style.filter = filter
    }

    async function loadHandTalk() {
        return window.open("https://video.jumpy.digital/visitant/lobby/vans")
        // await setHtActive(true)
        // return status
    }

    return (
        <section className={styles.menuMobile}>
            <div className={styles.menuMobileContainer}>
                <div className={styles.menuMobileWrapper}>
                    {contactActive ?
                        <div className={`${styles.openedMenu} ${styles.contact}`}>
                            <div className={styles.openedMenuContainer}>
                                <Icon style={styles.phoneIcon} imageSrc={phoneIcon} />
                            </div>
                            <div className={styles.openedMenuContainer}>
                                <Icon style={styles.mailIcon} imageSrc={mailIcon} />
                            </div>
                            <div className={styles.openedMenuContainer}>
                                <Icon style={styles.audioIcon} imageSrc={audioIcon} />
                            </div>
                            <div className={styles.openedMenuContainer}>
                                <Icon style={styles.whatsappIcon} imageSrc={whatsappIcon} />
                            </div>
                        </div> : null
                    }
                     <ul>
                        <li>
                            <Icon href="/" style={styles.homeIcon} imageSrc={homeIcon} />
                            <span>Home</span>
                        </li>
                        <li>
                            <Icon href="/" style={styles.logoFooterIcon} imageSrc={logoFooterIcon} />
                            <span>Pessoalize</span>
                        </li>
                        <li>
                            <Icon href="/solution" style={styles.solutionsIcon} imageSrc={solutionsIcon} />
                            <span>Soluções</span>
                        </li>
                        {/*
                        <li>
                            <Icon style={styles.resultsIcon} imageSrc={resultsIcon} />
                            <span>Resultados</span>
                        </li>
                        <li>
                            <Icon style={styles.exploreIcon} imageSrc={exploreIcon} />
                            <span>Explorar</span>
                        </li>
                        <li onClick={() => toggleContact()} className={contactActive ? 'selected' : ''}>
                            <Icon style={styles.contactIcon} imageSrc={contactIcon} />
                            <span>Contato</span>
                        </li>
                        */}
                    </ul> 
                </div>
                <div
                    className={acessibilityActive ? `${styles.menuAcessibilityFixed} selected` : styles.menuAcessibilityFixed}
                    onClick={() => toogleAcessibility()}
                    role='button'
                    tabIndex='0'
                >
                    <Icon style={styles.acessibilityIcon} imageSrc={acessibilityIcon} />
                    <span>Acessibilidade</span>

                </div>
                {acessibilityActive ?
                    <div className={styles.openedMenu}>
                        <div className={styles.openedMenuContainer} onClick={() => toogleTheme()}>
                            <Icon style={styles.menuVisual} imageSrc={menuVisualIcon} />
                        </div>
                        <div className={styles.openedMenuContainer} onClick={loadHandTalk} role='button' tabIndex='0'>
                            <Icon style={styles.menuHand} imageSrc={menuHandIcon} />
                        </div>
                    </div> : null
                }
            </div>
        </section>
    )
}

export default MenuMobile
