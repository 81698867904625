
import { useEffect, useState } from 'react'
import { throttle, isMobile } from '../utils/utils'

export default function useScrollEffect (defaultClass, newClass, limit = 200, useMobile) {
    const [elementClass, setElementClass] = useState(defaultClass)
    
    useEffect(() => {
        if (!useMobile && isMobile()) {
            return
        }
        
        handleHeaderScroll()
        window.addEventListener('scroll', throttle(handleHeaderScroll, 10), {passive: true})

        return () => { 
            window.removeEventListener('scroll', throttle(handleHeaderScroll, 10), {passive: true})
        }
    })

    function handleHeaderScroll () {
        if (window.scrollY > limit) {
            return setElementClass(`${defaultClass} ${newClass}`)
        }
        return  setElementClass(`${defaultClass}`)
    }

    return elementClass
}
