import React from 'react'

import { Link } from "gatsby"

function Icon ({style, imageSrc, clickHandler, type, href, role}) {
    function iconClick () {
        if (typeof clickHandler !== 'function') {
            return
        }

        return clickHandler()
    }

    function handleKeyPress ({ key }) {
        if (typeof clickHandler !== 'function') {
            return
        }

        if (key === 'Enter') {
            clickHandler()
        }
    }

    return (
        <div onClick={() => iconClick()} onKeyPress={(e) => handleKeyPress(e)} className={style} role={role}>
            <Link to={href}  rel='noreferrer' tabIndex='0'>
                <i>
                    <img src={imageSrc} alt={type}></img>
                </i>
            </Link> 
        </div>
    )
}

export default Icon
