import React, { useState, useContext, useEffect } from "react"
import { CSSTransition } from 'react-transition-group'

import styles from '../../styles/layout.module.scss'
import '../../styles/effects.css'

import { AppContext } from '../../../provider'
import Backdrop from '../BackDrop/backDrop'
import Header from "../Header/header"
import Footer from "../Footer/footer"
import MenuContainer from "../Menu/menuContainer"
import Menu from "../Menu/menu"
import Acessibility from "../Acessibility/acessibility"
import MenuMobile from "../Menu/MenuMobile"

function Layout({ children }) {
    const { isMobileDevice } = useContext(AppContext) || {}
    const [displayMenu, setDisplayMenu] = useState({
        isOpen: false,
        menuOpen: false,
        acessibilityOpen: false
    })

    useEffect(() => {
        window.addEventListener('keydown', handleFirstTab)
        return () => window.removeEventListener('keydown', handleFirstTab)
    })

    let menuMobile
    if (isMobileDevice) {
        menuMobile = <MenuMobile />
    }

    function handleFirstTab(e) {
        if (e.keyCode === 9) {
            document.body.classList.add('user-is-tabbing')
            window.removeEventListener('keydown', handleFirstTab);
            window.addEventListener('mousedown', handleMouseDownOnce);
        }
    }

    function handleMouseDownOnce() {
        document.body.classList.remove('user-is-tabbing');

        window.removeEventListener('mousedown', handleMouseDownOnce);
        window.addEventListener('keydown', handleFirstTab);
    }

    function displayMenuHandler(containerState, menuState, acessibilityStante) {
        setDisplayMenu({
            isOpen: containerState,
            menuOpen: menuState,
            acessibilityOpen: acessibilityStante
        })
    }

    function showComponent() {
        if (displayMenu.menuOpen) {
            return <Menu />
        } else if (displayMenu.acessibilityOpen) {
            return <Acessibility />
        }
    }

    return (
        <div style={{
            height: '100%'
        }}>
            <Header
                displayMenuHandler={displayMenuHandler}
                displayMenu={displayMenu}
            />
            <Backdrop show={displayMenu.isOpen} />
            <CSSTransition
                in={displayMenu.isOpen}
                timeout={500}
                classNames="node"
            >
                <MenuContainer
                    showMenu={displayMenu.isOpen}
                    displayMenu={displayMenu}
                    displayMenuHandler={displayMenuHandler}
                >
                    {showComponent()}
                </MenuContainer>
            </CSSTransition>
            {menuMobile}

            <main className={styles.mainContainer}>
                {children}
                <Footer />
            </main>
        </div>
    )
}

export default Layout
